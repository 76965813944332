var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mb-12",attrs:{"outlined":"","min-height":"70vh"}},[_c('v-text-field',{staticClass:"search-products-input",attrs:{"placeholder":"Buscar productos por nombre o categoria","prepend-inner-icon":"restaurant","append-icon":"search","hide-details":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","loading":_vm.loading},on:{"click":_vm.refreshProducts}},on),[_c('v-icon',[_vm._v("refresh")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.L('Actualizar Productos')))])])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.loading)?_c('v-row',{staticClass:"text-center py-6 px-8",attrs:{"dense":""}},_vm._l((24),function(n){return _c('v-col',{key:n,attrs:{"cols":"12","sm":"6","md":"3","lg":"2"}},[_c('v-skeleton-loader',{attrs:{"height":"120","type":"card"}})],1)}),1):(_vm.totalCount > 0)?[_c('categories'),_c('v-divider'),_c('vue-perfect-scrollbar',{staticClass:"drawer-products--scroll",attrs:{"settings":_vm.scrollSettings}},[_c('v-item-group',{staticClass:"pa-2"},[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.list),function(pro,k){return _c('v-col',{key:k,attrs:{"cols":"4","md":"2","lg":"15"}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-fade-transition',[_c('v-card',{staticClass:"d-flex align-center",attrs:{"color":active ? 'grey lighten-4' : null,"outlined":"","disabled":(pro.service_type != 'VP' && parseFloat(pro.stock) <= 0) || pro.loading,"elevation":hover || active ? 4 : 0},on:{"click":function($event){return _vm.addProduct(pro)}}},[_c('v-chip',{staticClass:"chip-price mt-1 ml-1",attrs:{"small":"","color":"grey lighten-4"}},[_vm._v(_vm._s(_vm.currency)+" "+_vm._s(pro.price_wt))]),((pro.service_type != 'VP' && parseFloat(pro.stock) <= 0))?_c('v-chip',{staticClass:"chip-stock mt-1 mr-1",attrs:{"dark":"","small":"","color":"red"}},[_vm._v(_vm._s(pro.stock))]):_vm._e(),(pro.image_url != null)?_c('v-img',{staticClass:"white--text align-end",attrs:{"lazy-src":pro.image_url,"src":pro.image_url}}):_c('v-avatar',{staticStyle:{"height":"147px","width":"100%"}},[_c('v-icon',{staticClass:"mb-4",attrs:{"size":"100px"}},[_vm._v(_vm._s(pro.line_id == _vm.lineBar ? 'local_bar' : 'lunch_dining'))])],1),_c('v-card-text',{staticClass:"caption text-center grey lighten-4 pa-0 pro-image-text"},[_vm._v(" "+_vm._s(pro.name)+" ")]),(pro.loading)?_c('v-card-text',{staticClass:"text-center py-2",staticStyle:{"position":"absolute"}},[_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"blue darken-4","indeterminate":""}})],1):_vm._e()],1)],1)]}}],null,true)})]}}],null,true)})],1)}),1)],1)],1)]:_c('v-row',{staticClass:"d-flex flex-wrap text-center justify-space-around py-12",attrs:{"dense":""}},[_c('v-col',{staticClass:"mt-12",attrs:{"cols":"12","lg":"12"}},[_c('v-icon',{attrs:{"size":"100px","color":"success"}},[_vm._v("shopping_bag")])],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('h4',[_vm._v("No hay Productos disponibles")])]),_c('span',{staticClass:"caption mb-4"},[_vm._v(" Parece que todavía no hay ningún producto disponible "),_c('br'),_vm._v(" Por favor, inténtalo más tarde. Los productos disponiles aparecerán próximamente ")])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }